<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <!-- 标题 -->
        <div>
          <span class="title">编辑车辆作业路线</span>
        </div>
        <!-- 左侧非地图部分 -->
        <div class="content-main-left">
          <div class="content-main-hw">
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              :inline="true"
              label-position="right"
              label-width="130px"
            >
              <el-form-item label="线路名称" prop="routeName" style="margin-bottom: 0px">
                <el-input v-model="form.routeName" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="所属标段" prop="bidId">
                <el-cascader
                  v-model="form.bidId"
                  placeholder="请选择"
                  :options="bidList"
                  :show-all-levels="false"
                  filterable
                  @change="hanldBidId"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="起始点">
                <el-input v-model="form.startPoint" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="结束点">
                <el-input v-model="form.endPoint" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="道路类型" prop="routeType">
                <el-select v-model="form.routeType" placeholder="请选择" filterable>
                  <el-option
                    v-for="item in roadTypeOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="主车道数" prop="mainLaneNum">
                <el-input v-model="form.mainLaneNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="辅道数量">
                <el-input v-model="form.sideLaneNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="隔离带">
                <el-checkbox v-model="form.hasIsolation"></el-checkbox>
              </el-form-item>
              <el-form-item label="保洁等级">
                <el-select v-model="form.cleanLevel" placeholder="请选择" filterable>
                  <el-option
                    v-for="item in cleanLevelOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="长度(m)">
                <el-input v-model="form.routeLen" placeholder="0" maxlength="20" disabled></el-input>
              </el-form-item>

              <el-form-item label="要求趟数(扫路车)">
                <el-input
                  placeholder="请输入内容"
                  v-model="form.sweeperTripNum"
                  class="input-with-select"
                >
                  <el-button slot="append" icon="el-icon-plus" @click="addItem('机械化清扫')">新增</el-button>
                </el-input>
              </el-form-item>

              <el-form-item label="每趟次数(扫路车)">
                <el-input v-model="form.sweeperTripTime	" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>

              <el-form-item label="要求趟数(清洗车)">
                <el-input
                  placeholder="请输入内容"
                  v-model="form.sprinklerTripNum"
                  class="input-with-select"
                >
                  <el-button slot="append" icon="el-icon-plus" @click="addItem('机械化清洗')">新增</el-button>
                </el-input>
              </el-form-item>

              <el-form-item label="每趟次数(清洗车)">
                <el-input v-model="form.sprinklerTripTime" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item class="line-wrap" label="备注">
                <div>
                  <el-input
                    v-model="form.routeRemark"
                    type="textarea"
                    :rows="4"
                    placeholder="请输入"
                    style="width: 95%"
                  ></el-input>
                </div>
              </el-form-item>
              <!-- 车辆作业班次清洗车 -->
              <el-form-item class="line-wrap" label="车辆作业班次">
                <p class="prompt">提示：该班次为车辆排班所选信息，新增作业线路时请先保存基础信息再进行车辆排班）</p>
              </el-form-item>

              <!-- -----------------------机械化清扫---------------------- -->
              <div
                class="item-wrap"
                style="background-color: rgb(249, 249, 249)"
                v-for="(frequency1, index) in form.frequency1"
                :key="frequency1.jobType+''+index"
              >
                <div class="close-btn">
                  <i class="el-icon-close" @click="removeItem(frequency1,index,1)"></i>
                </div>

                <el-form-item :label="frequency1.jobType"></el-form-item>
                <el-form-item
                  :label="'班次名称'"
                  :prop="'frequency1.' + index + '.shiftName'"
                  :rules="{
                  required: true, message: '不能为空', trigger: 'blur'
                }"
                >
                  <el-input v-model="frequency1.shiftName" placeholder="请输入班次名称"></el-input>
                </el-form-item>
                <el-form-item :label="'时间'" required>
                  <el-col class="time-s" :span="11">
                    <el-form-item
                      :prop="'frequency1.' + index + '.shiftStartTime'"
                      :rules="{
                        required: true, message: '不能为空', trigger: 'change',
                      }"
                    >
                      <el-time-select
                        placeholder="起始时间"
                        v-model="frequency1.shiftStartTime"
                        :picker-options="{
                          start: '00:00',
                          step: '00:05',
                          end: '23:55'
                        }"
                      ></el-time-select>
                    </el-form-item>
                  </el-col>
                  <el-col style="text-align: center" :span="2">-</el-col>
                  <el-col class="time-s" :span="11">
                    <el-form-item
                      :prop="'frequency1.' + index + '.shiftEndTime'"
                      :rules="{
                      required: true, message: '不能为空', trigger: 'change',
                      }"
                    >
                      <el-time-select
                        placeholder="结束时间"
                        v-model="frequency1.shiftEndTime"
                        :picker-options="{
                          start: '00:00',
                          step: '00:05',
                          end: '23:55',
                          minTime: frequency1.shiftStartTime
                        }"
                      ></el-time-select>
                    </el-form-item>
                  </el-col>
                </el-form-item>
              </div>
              <div
                v-if="form.frequency1.length > 0 && form.frequency2.length > 0"
                style="width:96%;margin-bottom: 15px;border-bottom:1px dashed"
              ></div>
              <!-- -----------------------机械化清洗----------------------- -->
              <div
                class="item-wrap"
                style="background-color: rgb(245, 248, 255)"
                v-for="(frequency2, index) in form.frequency2"
                :key="frequency2.jobType+''+index"
              >
                <div class="close-btn">
                  <i class="el-icon-close" @click="removeItem(frequency2,index,2)"></i>
                </div>

                <el-form-item :label="frequency2.jobType"></el-form-item>
                <el-form-item
                  :label="'班次名称'"
                  :prop="'frequency2.' + index + '.shiftName'"
                  :rules="{
                  required: true, message: '不能为空', trigger: 'blur'
                }"
                >
                  <el-input v-model="frequency2.shiftName" placeholder="请输入班次名称"></el-input>
                </el-form-item>
                <el-form-item :label="'时间'" required>
                  <el-col class="time-s" :span="11">
                    <el-form-item
                      :prop="'frequency2.' + index + '.shiftStartTime'"
                      :rules="{
                        required: true, message: '不能为空', trigger: 'change',
                      }"
                    >
                      <el-time-select
                        placeholder="起始时间"
                        v-model="frequency2.shiftStartTime"
                        :picker-options="{
                          start: '00:00',
                          step: '00:05',
                          end: '23:55'
                        }"
                      ></el-time-select>
                    </el-form-item>
                  </el-col>
                  <el-col style="text-align: center" :span="2">-</el-col>
                  <el-col class="time-s" :span="11">
                    <el-form-item
                      :prop="'frequency2.' + index + '.shiftEndTime'"
                      :rules="{
                      required: true, message: '不能为空', trigger: 'change',
                      }"
                    >
                      <el-time-select
                        placeholder="结束时间"
                        v-model="frequency2.shiftEndTime"
                        :picker-options="{
                          start: '00:00',
                          step: '00:05',
                          end: '23:55',
                          minTime: frequency2.shiftStartTime
                        }"
                      ></el-time-select>
                    </el-form-item>
                  </el-col>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
        <!-- 地图部分 -->
        <ayl-map-line v-model="mapData" :accuracy="2" @change="onMapChange"></ayl-map-line>
      </div>

      <div class="tac mt40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
        <!-- <el-button class="btn-hw" @click="onSubmitAndNext">保存并录入下一条</el-button> -->
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      debug: 0,
      nav: [{ name: "编辑车辆作业路线" }],
      map: null,
      theMouseTool: null,
      radio: 1,
      valueImageJ: true,
      submitLoading: false,
      //地图数据
      mapData: {
        lineScope: null, //形如  [[118,36],[117,32],[116,113]]
        scope: null //（1个）围栏范围，经纬度点阵数组，形如  [[118,36],[117,32],[116,113]]
      },
      form: {
        routeName: null, //线路名称
        bidId: null, //所属标段
        startPoint: null, //起始点
        endPoint: null, //结束点
        routeType: null, //道路类型
        mainLaneNum: null, //主车道数
        sideLaneNum: null, //辅道数量
        hasIsolation: null, // 隔离带
        cleanLevel: null, // 保洁等级
        routeLen: null, // 长度(m)

        sweeperTripNum: 0, // 要求趟数(扫路车)
        sweeperTripTime: 0, //每趟次数(扫路车)
        sprinklerTripNum: 0, // 要求趟数(清洗车)
        sprinklerTripTime: 0, //每趟次数(清洗车)

        routeRemark: null, //备注
        // 机械化清扫
        frequency1: [
          // {
          //   jobType: null, // 类型
          //   shiftName: null, //班次名称
          //   shiftStartTime: null,
          //   shiftEndTime: null
          // }
        ],
        // 机械化清洗
        frequency2: [
          // {
          //   jobType: null, // 类型
          //   shiftName: null, //班次名称
          //   shiftStartTime: null,
          //   shiftEndTime: null
          // }
        ]
      },
      tabsData: [], //作业类型
      // 所属标段下拉
      bidList: [],
      // 道路类型下拉
      roadTypeOption: [
        { value: "0", label: "单向车道" },
        { value: "1", label: "双向车道" }
      ],
      cleanLevelOption: [
        { value: "0", label: "特级" },
        { value: "1", label: "一级" },
        { value: "2", label: "二级" },
        { value: "3", label: "三级" }
      ],
      rules: {
        routeName: {
          required: true,
          message: "请输入线路名称",
          trigger: "blur"
        },
        bidId: {
          required: true,
          message: "请选择所属标段",
          trigger: "change"
        },
        routeType: {
          required: true,
          message: "请选择道路类型",
          trigger: "change"
        },
        mainLaneNum: {
          required: true,
          message: "请输入主车道数",
          trigger: "blur"
        }
      }
    };
  },
  methods: {
    onMapChange(mapData) {
      if (mapData) this.form.routeLen = "" + this.mapData.lineLength;
    },
    //获取所属标段
    hanldBidId(arr) {
      let self = this;
      self.form.bidId = arr[1]; //所属标段
      if (self.mapData && self.form.bidId !== "") {
        self.mapData.move2center = true;
        self.$api_hw
          .common_getBidInfo({
            id: self.form.bidId
          })
          .then(res => {
            self.log("mk", res.bidCoordinateScope);
            try {
              self.mapData.scope = Array.isArray(res.bidCoordinateScope)
                ? res.bidCoordinateScope
                : JSON.parse(res.bidCoordinateScope);
            } catch (e) {
              self.mapData.scope = null;
            }
          })
          .catch(() => {
            self.mapData.scope = null;
          });
      }
    },
    //新增扫路车
    addItem(val) {
      if (val == "机械化清扫") {
        this.form.frequency1.push({
          jobType: val, // 类型
          shiftName: null, //班次名称
          shiftStartTime: null,
          shiftEndTime: null
        });
        // 获取次数
        this.form.sweeperTripNum = this.form.frequency1.length;
        if (
          this.form.frequency1.length > 0 &&
          this.form.sweeperTripTime === 0
        ) {
          this.form.sweeperTripTime = 1;
        } else if (this.form.frequency1.length == 0) {
          this.form.sweeperTripTime = 0;
        }
      } else {
        this.form.frequency2.push({
          jobType: val, // 类型
          shiftName: null, //班次名称
          shiftStartTime: null,
          shiftEndTime: null
        });
        this.form.sprinklerTripNum = this.form.frequency2.length;
        // 获取次数
        if (
          this.form.frequency2.length > 0 &&
          this.form.sprinklerTripTime === 0
        ) {
          this.form.sprinklerTripTime = 1;
        } else if (this.form.frequency2.length == 0) {
          this.form.sprinklerTripTime = 0;
        }
      }
    },

    //删除扫路车
    removeItem(item, index, type) {
      if (type === 1) {
        this.form.frequency1.splice(index, 1);
        this.form.sweeperTripNum = this.form.frequency1.length;
        this.form.sweeperTripNum = this.form.frequency1.length;
        // 获取次数
        if (
          this.form.frequency1.length > 0 &&
          this.form.sweeperTripTime === 0
        ) {
          this.form.sweeperTripTime = 1;
        } else if (this.form.frequency1.length == 0) {
          this.form.sweeperTripTime = 0;
        }
      } else {
        this.form.frequency2.splice(index, 1);
        this.form.sprinklerTripNum = this.form.frequency2.length;
        // 获取次数
        if (
          this.form.frequency2.length > 0 &&
          this.form.sprinklerTripTime === 0
        ) {
          this.form.sprinklerTripTime = 1;
        } else if (this.form.frequency2.length == 0) {
          this.form.sprinklerTripTime = 0;
        }
      }
    },

    // 提交
    async submit() {
      let arr = [...this.form.frequency1, ...this.form.frequency2];
      let arrParams = arr.map(item => {
        return {
          jobType: item.jobType == "机械化清洗" ? "0" : "1",
          shiftName: item.shiftName,
          shiftStartTime: item.shiftStartTime,
          shiftEndTime: item.shiftEndTime,
          shiftId: item.shiftId ? item.shiftId : null
        };
      });
      let objParams = JSON.parse(JSON.stringify(this.form));
      objParams.hasIsolation = this.form.hasIsolation ? "0" : "1";
      objParams.sweeperTripNum = this.form.sweeperTripNum + "";
      objParams.sprinklerTripNum = this.form.sprinklerTripNum + "";
      objParams.sweeperTripTime = this.form.sweeperTripTime + "";
      objParams.sprinklerTripTime = this.form.sprinklerTripTime + "";

      delete objParams.frequency1;
      delete objParams.frequency2;
      //补上线路坐标 by mabo
      objParams.routeCoordinateScope = JSON.stringify(this.mapData.lineScope);
      let params = {
        sanitationRoutePlanShiftVO: {
          sanitationRoutePlanVO: objParams,
          sanitationShiftManageVOList: arrParams
        }
      };
      await this.$api_hw.vehicleOperation_updateRouteShift(params);
    },
    // 提交表单
    async onSubmit(isGoNext) {
      let _this = this;
      if (_this.submitLoading) return; //防止重复提交
      _this.$refs.form.validate(async valid => {
        if (!valid) return;

        _this.submitLoading = true;
        try {
          if (!this.mapData.lineScope) {
            this.$notify.error({
              title: "注意",
              message: "请标段范围内绘制车辆作业线路"
            });
            return;
          }
          await _this.submit();
          _this.$notify({
            title: "成功",
            message: `${_this.nav[0].name}成功`,
            type: "success"
          });
          BUS.$emit(BUSEVENT.REFRESH_VEHICLE_OPERATION_MANAGEMENT);
          if (isGoNext !== true) {
            this.$refs["form"].resetFields();
            _this.onClose(
              "/district-management/vehicle-operation/vehicle-operation-management"
            );
          } else {
            _this.form = {};
          }
        } catch (e) {
          // console.error(e);
        } finally {
          _this.submitLoading = false;
        }
      });
    },
    // 保存并录入下一条
    onSubmitAndNext() {
      this.onSubmit(true);
    },
    onClose(toPath) {
      if (toPath && typeof toPath == "string")
        // for 标签页
        BUS.$emit(BUSEVENT.CLOSE_NAV, [this.$route, toPath]);
      else {
        BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
      }
    }
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
    routes() {
      return this.$store.state.permission.routes;
    }
  },
  async activated() {
    //获取所属标段下拉
    this.bidList = await this.$api_hw.common_getBidNameAndIdList({});

    //获取编辑数据并处理+绑定数据
    let formData = await this.$api_hw.vehicleOperation_getRouteShiftInfo({
      id: this.$route.query.routeId
    });
    this.log(formData.sanitationRoutePlanVO);
    this.form = formData.sanitationRoutePlanVO;

    // 获取标段地图围栏范围
    await this.hanldBidId(["", formData.sanitationRoutePlanVO.bidId]);
    //补上线路坐标 by mabo
    this.mapData.lineScope = JSON.parse(
      formData.sanitationRoutePlanVO.routeCoordinateScope
    );

    this.form.hasIsolation = this.form.hasIsolation == "1" ? false : true;
    this.form.sweeperTripNum = this.form.sweeperTripNum / 1;
    this.form.sprinklerTripNum = this.form.sprinklerTripNum / 1;

    let arr1 = [];
    let arr2 = [];
    formData.sanitationShiftManageVOList.forEach(item => {
      if (item.jobType === "1") {
        arr1.push({
          jobType: "机械化清扫",
          shiftName: item.shiftName,
          shiftStartTime: item.shiftStartTime,
          shiftEndTime: item.shiftEndTime,
          shiftId: item.shiftId
        });
      } else {
        arr2.push({
          jobType: "机械化清洗",
          shiftName: item.shiftName,
          shiftStartTime: item.shiftStartTime,
          shiftEndTime: item.shiftEndTime,
          shiftId: item.shiftId
        });
      }
    });
    this.$set(this.form, "frequency1", arr1);
    this.$set(this.form, "frequency2", arr2);
  }
};
</script>

<style lang='sass' scoped>
.clearBox
  .content-box
    .content-main
      display: inline-block
      width: 100%
      &-left
        float: left
        width: 808px
      &-right
        float: left
        height: 777px
        width: calc(100% - 808px)
        background: #EDEDED
  .order
    overflow-x: visible !important
    &-type
      display: inline-block
      padding-right: 14px
      font-size: 14px
      font-family: Microsoft YaHei
      font-weight: 400
      color: rgba(102,102,102,1)
  .title
    width: 64px
    height: 17px
    font-size: 16px
    font-family: Microsoft YaHei
    font-weight: 400
    color: $-color-primary-3
    line-height: 18px
  .el-checkbox-group
    display: inline-block
  .map-popover
    position: absolute
    z-index: 2
    top: 12px
    right: 12px
    width: 180px
    height: 32px
    background: rgba(255,255,255,1)
    box-shadow: 0px 2px 8px 0px rgba(31,46,41,0.3)
    border-radius: 2px

  /deep/ .el-radio__input.is-checked .el-radio__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-radio__input.is-checked + .el-radio__label
    color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label
    color: $-color-primary-3
  /deep/ .el-form-item
    width: 48%


.prompt
  color: #FF8C1A
  font-size: 12px

.content-box .content-main-hw
  padding-right: 50px
/deep/ .el-form
  display: flex;
  flex-wrap: wrap;
  justify-centent: space-between;
  .el-form-item
    display: flex;
  .el-form-item--mini .el-form-item__content
    flex: 1;
  .el-cascader--mini,
  .el-select
    width: 100%
  .line-wrap
    width: 100%;
    .el-form-item__content
      width: 575px;
/deep/ .el-form--inline .el-form-item
      margin-right: 0;
.add-item .el-form-item
  width: 100%
  .el-input
    width: 350px;



.item-wrap
  width: 96%
  margin-bottom: 20px
  padding: 5px 5px 0;
  border: 1px solid #ddd
  box-sizing: border-box
  border-radius: 4px
  .close-btn
    text-align: right;
    font-size: 20px
    cursor: pointer
  /deep/ .el-form-item
    width: 65%
.time-s /deep/.el-form-item,
.time-s /deep/ .el-date-editor.el-input
  width: 100%
/deep/ .el-input.is-disabled .el-input__inner
  color: #333d42
</style>
